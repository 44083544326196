import Vue from 'vue'
import VueMeta from 'vue-meta'
import Tooltip from 'vue-directive-tooltip'
import Notifications from 'vue-notification'
import infiniteScroll from 'vue-infinite-scroll'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

//supporting files
import Errors from '../../libraries/errors'

import { ColorPanel, ColorPicker } from 'one-colorpicker'

import { mapGetters, mapState } from 'vuex'

// app components
import DocumentsPage from '../../documents/pages.vue'
import Bingo from '../../documents/bingo/bingo.vue'
import Flashcard from '../../documents/flashcard/flashcard.vue'
import Save from '../../widgets/save.vue'
import LastSaved from '../../widgets/LastSaved.vue'
import SideBar from '../../components/widgets/SideBar.vue'
import Loader from '../../widgets/Loader.vue'
import LogoLoader from '../../components/logos/LogoLoader.vue'
import SubscriptionSuspended from '../../components/modals/SubscriptionSuspended.vue'

import Lang from '../../libraries/Lang'

// Plugins
import { EventTracking } from '../../plugins/EventTracking'

// Repositories
import { Nav } from '../../repos/Nav'
import { Fonts } from '../../repos/Fonts'
import { Modals } from '../../repos/Modals'

//Import the VueX store
import store from '../../store/document'

// Mixins
import WithBrand from '../../mixins/WithBrand'
import WithPageViewTracking from '../../mixins/WithPageViewTracking'
import DocumentHelper from '../../mixins/DocumentHelper'
import DocumentResizeListener from '../../mixins/DocumentResizeListener'
import StatsigHelper from '../../mixins/StatsigHelper'
import TopnavUtil from '../../mixins/TopnavUtil'
import PushListener from '../../mixins/PushListener'
import LogInWatcher from '../../mixins/LogInWatcher'
import WithGrooveHq from '../../mixins/WithGrooveHq'
import WithCustomScrollbar from '../../mixins/WithCustomScrollbar'
import WithImageUpload from '../../mixins/WithImageUpload'
import WithAutoScroll from '../../mixins/WithAutoScroll'
import VueForceNextTick from 'vue-force-next-tick'
import UserEvent from '../../objects/UserEvent'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.use(VueForceNextTick)

Vue.use(Tooltip)
Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(infiniteScroll)
Vue.use(EventTracking, store)

//bootstrap components
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Color pickers
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.component('SideBar', SideBar)
Vue.component('Loader', Loader)
Vue.component('LogoLoader', LogoLoader)
Vue.component('SubscriptionSuspended', SubscriptionSuspended)

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

//set up some Vue values
Vue.prototype.$lang = new Lang()

//repos
Vue.prototype.$nav = Nav
Vue.prototype.$fonts = Fonts
Vue.prototype.$modals = Modals

Sentry.init({
    Vue,
    dsn: window.sentryDSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: [window.sentryTracingOrigin, /^\//],
        }),
        new Sentry.Replay({
            networkCaptureBodies: true,
            networkDetailAllowUrls: [window.location.origin],
        }),
    ],
    logErrors: true,
    tracesSampleRate: window.sentryTraceSampleRate,
    replaysOnErrorSampleRate: 1.0,
})

window.app = new Vue({
    store,
    el: '#app',
    components: {
        MaintenanceAlert: () => import('../../components/MaintenanceAlert.vue'),
        //subscription/auth
        Login: () => import('../../components/Login.vue'),

        //Modals
        CheckoutModal: () => import('../../components/modals/CheckoutModal.vue'),
        FilenameModal: () => import('../../components/FilenameModal.vue'),
        PrintableModal: () => import('../../components/PrintableModal.vue'),
        ErrorHandlingModal: () => import('../../components/ErrorHandlingModal.vue'),
        OnboardingModal: () => import('../../components/OnboardingModal.vue'),
        Canny: () => import('../../components/Canny.vue'),
        MwmModal: () => import('../../widgets/modal.vue'),
        OneClickSuccess: () => import('../../components/OneClickSuccessModal.vue'),
        Paywall: () => import('../../components/Paywall.vue'),
        CancelSubscription: () => import('../../components/modals/CancelSubscription.vue'),

        //Documents page
        DocumentsPage,

        //Bingo
        Bingo,

        //Flashcard
        Flashcard,

        // different forms
        Save,
        LastSaved,

        // Faq and Copyright
        FaqSection: () => import('../../documents/partials/FaqSection.vue'),
        CopyrightDisclaimers: () => import('../../documents/partials/CopyrightDisclaimers.vue'),

        // Print interuptions (not published/not logged in)
        PrintCovers: () => import('../../components/PrintCovers.vue'),

        IconMagnifyPlus: () => import('../../stories/components/icons/IconMagnifyPlus.vue'),

        MainMenu: () => import('../../widgets/main-menu.vue'),
        FontSelect: () => import('../../widgets/font-select.vue'),
    },
    mixins: [
        WithBrand,
        WithPageViewTracking,
        DocumentHelper,
        StatsigHelper,
        TopnavUtil,
        PushListener,
        LogInWatcher,
        DocumentResizeListener,
        WithGrooveHq,
        WithCustomScrollbar,
        WithImageUpload,
        WithAutoScroll,
    ],
    data: {
        zoom: 1,
        subscription_init_load: true,
        mobileAlertDismissed: false,
        mainNavIsActive: false,
        errors: new Errors(),
        clear_items_modal: {
            is_open: false,
        },
        mediumWidth: 1304,
        workspaceWidth: 730,
        resizedUp: false,
        currentWidth: window.innerWidth,
        previousWidth: window.innerWidth,
        allowRescale: false,
        exitHandled: false,
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            documentTypes: 'document/allDocumentTypes',
            documentRequiresPagination: 'document/documentRequiresPagination',
            zoomLevels: 'document/getZoomLevels',
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            hasPlans: 'subscription/hasPlans',
            error: 'document/apiCallFeedback',
            persistAction: 'document/immediatePersistAction',
            answerable: 'document/answerable',
            isImageUploaderOpen: 'document/isImageUploaderOpen',
            textColorFocus: 'abtests/textFocusColor',
            isBodyStyleSettingsOpen: 'document/isBodyStyleSettingsOpen',
            isHeaderSticky: 'abtests/stickyHeader',
            backgroundColor: 'abtests/backgroundColor',
            showSuspendedNotice: 'subscription/showSuspendedNotice',
        }),
        ...mapState(['user', 'document', 'subscription']),
        screenSizeTooSmall() {
            return this.currentWidth < 925
        },
        documents() {
            return this.document.documents.length
        },
        intentToSaveText() {
            if (!this.isLoggedIn) return 'Save'

            if (this.document.is_loading) return 'Saving...'

            if (this.document.save_document) return 'Saved'

            return 'Save'
        },
        loadCompleted() {
            return {
                type: this.worksheet.type,
                fonts: this.$fonts.ready,
            }
        },
        showDefinition() {
            return ['matching', 'fill_in_the_blank'].includes(this.worksheet.type)
        },
        checkoutPage() {
            if (this.isFlashcard) return 'flashcard'

            if (this.isBingo) return 'bingo'

            return 'worksheet'
        },
        saveComponentStyle() {
            let styles = [{ minWidth: '40%' }, { width: '100%' }, { maxWidth: this.screenSizeTooSmall ? '100%' : '50%' }]

            if (this.shouldReduceOpacity) {
                styles = [...styles, ...[{ opacity: 0.5 }, { pointerEvents: 'none' }]]
            }

            return styles
        },
        answer_key: {
            get() {
                return this.isBingo || this.isFlashcard ? 0 : this.document.show_answer_key
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    show_answer_key: value,
                })
            },
        },
        zoomContainerClass() {
            return {
                'zoom-container-sticky': this.isHeaderSticky,
            }
        },
        shouldReduceOpacity() {
            return this.isImageUploaderOpen || this.isBodyStyleSettingsOpen
        },
    },
    watch: {
        documents() {
            this.$nextTick(() => {
                if (document.getElementById('workspace')) {
                    this.$store.dispatch('document/scaleDocument')
                }
            })
        },
        loadCompleted: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    if (this.isLoggedIn && window.doc.id) {
                        this.$store.dispatch('document/setUpDocumentUpdateInterval')
                    }
                    if (this.loadCompleted.fonts) {
                        this.$store.dispatch('document/paginateItems')
                    }
                })
            },
        },
        documentRequiresPagination: {
            // repaginate anytime the document changes.
            deep: true,
            handler() {
                this.$nextTick(() => {
                    if (this.loadCompleted.fonts) {
                        this.$store.dispatch('document/paginateItems')
                    }
                })
            },
        },
        showSuspendedNotice(newVal) {
            if (newVal) {
                this.$bvModal.show('subscription-suspended')
            }
        },
    },
    async beforeCreate() {
        await this.$store.dispatch('user/initialize')
        //Initialize statsig before creating document
        await this.$store.dispatch('abtests/initialize', {
            completed: async () => {
                await this.startAbTests()

                if (!this.document.entity_type) {
                    this.logNavigation(UserEvent.CREATE_DOCUMENT)
                }
            },
        })
    },
    async created() {
        this.visibilityChangeHandler = () => {
            if (this.error || document.visibilityState !== 'hidden') return
            this.handleDocumentVisibilityChange()
        }
        window.addEventListener('visibilitychange', this.visibilityChangeHandler)

        window.onbeforeunload = () => {
            this.handleExitingDocument()
        }

        if (window.doc) {
            await this.$store.dispatch('document/setWindowDocument', window.doc)
        }

        if (window.copying) {
            this.setInitialDocumentValues()

            if (this.isLoggedIn) {
                this.$store.dispatch('document/setDocument', {
                    entity_type: window.doc.entity_type,
                })

                this.$nextTick(() => {
                    this.$store.dispatch(this.persistAction, true)
                })
            }
        }

        // pass the vue instance into the different stores
        this.$store.dispatch('subscription/setValue', {
            vue: this,
        })
        this.$store.dispatch('cards/setValue', {
            vue: this,
        })
        this.$store.dispatch('user/setValue', {
            vue: this,
        })
        this.$store.dispatch('document/setVueInstance', this)
        this.$store.dispatch('document/setModalInstance', this.$modals)

        if (
            this.isLoggedIn && // if we're logged in
            window.doc.id && // and the document has an id
            window.doc.user_id == this.user.user.id
        ) {
            //and the document belongs to the current user
            this.$store.dispatch('document/setSaveDocument', true) // enable document saving
        }

        if (this.isLoggedIn && window.doc.id) {
            this.$store.dispatch('document/fetchDocumentPurchaseStatus')
        }

        //check for mobileAlertDismissed in local storage
        this.mobileAlertDismissed = window.localStorage.getItem('mobileAlertDismissed') ? true : false
    },
    mounted() {
        //add pinterest JS library
        let pinterest = document.createElement('script')
        pinterest.setAttribute('src', '//assets.pinterest.com/js/pinit.js') //generate partial url
        pinterest.setAttribute('async', true)
        document.getElementsByTagName('head')[0].appendChild(pinterest)

        //add twitter JS library
        let twitter = document.createElement('script')
        twitter.setAttribute('src', 'https://platform.twitter.com/widgets.js') //generate partial url
        twitter.setAttribute('charset', 'utf-8')
        document.getElementsByTagName('head')[0].appendChild(twitter)

        let docZoom = localStorage.getItem('DocumentZoom')

        if (docZoom) {
            docZoom = JSON.parse(docZoom)
            if (docZoom.id === this.$store.state.document.id) {
                this.$store.commit('document/SET_DOCUMENT_ZOOM', docZoom.zoom)
                this.$store.dispatch('document/scaleDocument', true)
            } else {
                localStorage.removeItem('DocumentZoom')
            }
        }

        this.resizeHandler = (event) => {
            this.resizeDocument(event)
            this.updateResizeDirection()
        }
        window.addEventListener('resize', this.resizeHandler)

        window.document.addEventListener('DOMContentLoaded', (event) => {
            this.resizeDocument(event)
        })

        this.$store.dispatch('document/adjustZoomContainer')
        this.$store.dispatch('document/paginateItems')

        if (this.showSuspendedNotice) {
            this.$bvModal.show('subscription-suspended')
        }
    },
    beforeDestroy() {
        window.removeEventListener('visibilitychange', this.visibilityChangeHandler)
        window.removeEventListener('resize', this.resizeHandler)
        window.onbeforeunload = null
    },
    methods: {
        async startAbTests() {
            if (this.isWorksheet) {
                await this.$store.dispatch('abtests/loadWorksheetHeaderAndTitleLabelCombinationTests')
                await this.$store.dispatch('abtests/loadBackgroundColorTest')
                this.setBackgroundColor()
            }
            await this.$store.dispatch('abtests/loadStickHeaderTests')
            await this.$store.dispatch('abtests/loadStickerButtonColorAndTextTests')
            await this.$store.dispatch('abtests/loadWorksheetHeaderCheckBoxStyleTests')
            await this.$store.dispatch('abtests/loadAddWidgetBackgroundColorTests')
            await this.$store.dispatch('abtests/loadTextFocusColorTest')
            this.setTestTextFocusColor()
        },
        updateResizeDirection() {
            this.currentWidth = window.innerWidth

            if (this.currentWidth > this.previousWidth) {
                this.resizedUp = true
            } else if (this.currentWidth < this.previousWidth) {
                this.resizedUp = false
            }

            this.previousWidth = this.currentWidth
        },
        setZoom(size) {
            this.$store.dispatch('document/setZoom', size)
            this.$store.dispatch('document/scaleDocument', true)
            this.$store.dispatch('document/checkScroll')
        },
        isDocumentHidden(selectorId, containerId) {
            const selectedElement = document.getElementById(selectorId)
            const selectedElementBoundingRect = selectedElement.getBoundingClientRect()
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth

            let selectedElementRightBoundary = selectedElementBoundingRect.right

            if (containerId) {
                let containerElement = document.getElementById(containerId)
                let containerStyles = window.getComputedStyle(containerElement)
                let rightPadding = parseFloat(containerStyles.getPropertyValue('padding-right'))

                selectedElementRightBoundary += rightPadding
            }

            return selectedElementRightBoundary > viewportWidth
        },
        intentToSave() {
            if (this.isLoggedIn) {
                this.$modals.open('filename')
            } else {
                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                this.$logTrackingEvent(UserEvent.SIGN_UP_SAVE_DOCUMENT)
            }
        },
        setDocumentType(key) {
            this.$store.dispatch('document/setDocumentType', key).then(() => {
                this.$nav.setPanel('header', 'edit')
                this.$logTrackingEvent('button interface - ' + key)
            })
        },
        //mobile devices
        dismissMobileAlert() {
            this.mobileAlertDismissed = true
            window.localStorage.setItem('mobileAlertDismissed', true)
        },
        getDocumentType(key) {
            for (let i = 0; i < this.documentTypes; i++) {
                if (this.documentTypes[i].key == key) {
                    return this.documentTypes[i]
                }
            }
        },
        goto(e) {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.scrollIntoView(e.ref)
                }, 25)
            })
        },
        handleDocumentVisibilityChange() {
            if (this.exitHandled) return
            this.exitHandled = true

            this.handleTrackingEvent(UserEvent.PAGE_VISIBILITY_CHANGE)
        },
        handleExitingDocument() {
            if (this.exitHandled) return
            this.exitHandled = true

            this.handleTrackingEvent(UserEvent.LEAVING)
        },

        handleTrackingEvent(event) {
            if (!this.isLoggedIn) {
                this.$logTrackingEvent(event, undefined, undefined, true)
                return
            }

            if (!this.document.hasModification) {
                this.$logTrackingEvent(event, this.document.id, undefined, true)
                return
            }

            this.$store.dispatch('document/updateDocument')

            // TODO (if UserEvent.LEAVING):
            // - Set the has_modification param based on the document's state.
            // - This avoids duplicate media generation when publishing the document.
            // - Simply using !this.document.is_publishing breaks the media generation when leaving a document.
            this.$logTrackingEvent(event, this.document.id, true, true)
        },
        setBackgroundColor() {
            if (this.backgroundColor === '--background-color-default') return

            document.documentElement.style.backgroundColor = `var(${this.backgroundColor})`
            document.documentElement.style.backgroundAttachment = 'unset'
            document.documentElement.style.backgroundImage = 'unset'

            if (!this.isHeaderSticky) return

            const stickyHeaderElement = document.getElementsByClassName('zoom-container-sticky')[0]

            if (!stickyHeaderElement) return

            stickyHeaderElement.style.backgroundColor = `var(${this.backgroundColor})`
            stickyHeaderElement.style.backgroundAttachment = 'unset'
            stickyHeaderElement.style.backgroundImage = 'unset'
        },
        setTestTextFocusColor() {
            this.$nextTick(() => {
                document.body.style.setProperty('--focus-box-shadow', `0 0 0 0.2rem rgba(var(${this.textColorFocus}))`)
            })
        },
    },
})
