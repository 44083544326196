<template>
    <b-button :variant="checked ? 'primary' : 'outline-primary'" :size="size" :aria-label="ariaLabel" @click="toggleButton">
        <slot></slot>
    </b-button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        value: {
            type: [Boolean, Number],
            default: false,
        },
        size: {
            type: String,
            default: 'sm',
        },
        ariaLabel: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            checked: false,
        }
    },
    watch: {
        value(newValue) {
            this.checked = newValue
        },
    },
    mounted() {
        this.checked = this.value
    },
    methods: {
        toggleButton() {
            this.checked = !this.checked
            this.$emit('input', this.checked)
        },
    },
})
</script>
